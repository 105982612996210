import React, { useState } from "react";
import { api } from "../../utils/api";
import { useNavigate } from "react-router-dom";
import Button from "../../component/button";
import Hamburger from "../../component/hamburger";

const AdminBlogList = () => {
  const { data, isSuccess, isLoading } = api.useGet("/blog", {
    staleTime: Infinity,
  });
  return (
    <>
      {isLoading && <div className="text-center">Loading...</div>}
      {isSuccess && (
        <div className="flex flex-col m-auto h-full px-1 w-full items-center">
          {data.data.map((post) => (
            <BlogPost key={post._id} {...post} />
          ))}
        </div>
      )}
    </>
  );
};

const BlogPost = ({
  title,
  author,
  authorImage,
  coverImage,
  description,
  body,
  state,
  createdAt,
  _id: id,
}) => {
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  }).format(new Date(createdAt));

  const truncatedDescription =
    description && description.split(" ").slice(0, 15).join(" ");
  const truncatedBody =
    body &&
    new DOMParser()
      .parseFromString(body, "text/html")
      .querySelector("p")
      .textContent.split(" ")
      .slice(0, 15)
      .join(" ");

  const { mutateAsync, isLoading: stateUpdateIsLoading } = api.usePut(
    `/blog/${id}/state`,
  );

  const publishLabel = state === "published" ? "Make draft" : "Publish";

  const navigate = useNavigate();

  const updateProjectState = async () => {
    const stateToUpdate = state === "published" ? "draft" : "published";
    console.log(stateToUpdate);
    await mutateAsync({ state: stateToUpdate });
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <div className="relative border-b w-full md:w-[80%] lg:w-[50%] p-10 px-2">
      <div className="flex gap-2 items-center p-2">
        <Hamburger isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
        <div className="rounded-full overflow-hidden w-6 h-6">
          <img
            className="object-cover"
            alt={author}
            src={authorImage || "https://i.pravatar.cc/30?img=1"}
            width="24"
            height="24"
            loading="lazy"
          />
        </div>
        <div className="truncate max-w-[100px] leading-none">
          <p className="text-sm">{author}</p>
          <p className="text-sm">{formattedDate}</p>
        </div>
      </div>
      <div className="flex items-center">
        <div className="flex flex-col max-w-[75%]">
          <a href={`/blogs/${id}`}>
            <h2 className="p-2 font-semibold text-xs sm:text-[1.15rem]">
              {title}
            </h2>

            <div className="md:flex p-2 hidden">
              <p>
                {truncatedDescription} - {truncatedBody}
              </p>
            </div>
            <div className="md:hidden">
              <p className="text-xs sm:text-sm">{truncatedDescription}</p>
            </div>
          </a>
        </div>
        <div className="p-4 my-auto w-[122px] rounded-lg md:w-[182px] lg:w-[222px]">
          <img
            className="w-[200px] h-auto md:max-w-[100px] md:max-h-[100px] object-cover rounded-lg"
            src={coverImage}
            alt={title}
          />
        </div>
      </div>
      {isMenuOpen && (
        <div className="absolute top-20 right-0 left-0 z-10 bg-gray-100 p-2 flex flex-col items-center justify-between space-y-4 opacity-90">
          <button
            className="px-5 py-2 w-full bg-gray-500 hover:bg-gray-600 text-white font-bold rounded-md"
            onClick={() => navigate(`/admin/blog/${id}/edit`)}
          >
            Edit
          </button>
          <Button
            onClick={updateProjectState}
            isWorking={stateUpdateIsLoading}
            className="w-full bg-gray-500 hover:bg-gray-600 text-white"
          >
            {publishLabel}
          </Button>
        </div>
      )}
    </div>
  );
};

export default AdminBlogList;
