import React, { useState } from "react";
import draftToHtml from "draftjs-to-html";
import { useNavigate } from "react-router-dom";
import { AlertCircle, Save, Globe, Github } from "lucide-react";
import Form from "../../component/form";
import Button from "../../component/button";
import { api } from "../../utils/api";

const CreateProject = () => {
  const navigate = useNavigate();
  const { mutateAsync, isSuccess, isLoading, error } = api.usePost("/project");
  const [previewMode, setPreviewMode] = useState(false);
  const [formData, setFormData] = useState(null);

  const validateForm = (values) => {
    const errors = {};
    if (!values.name) errors.name = "Project name is required";
    if (!values.description) errors.description = "Description is required";
    if (values.liveUrl && !isValidUrl(values.liveUrl)) {
      errors.liveUrl = "Please enter a valid URL";
    }
    if (values.githubUrl && !isValidUrl(values.githubUrl)) {
      errors.githubUrl = "Please enter a valid URL";
    }
    if (!values.body) errors.body = "Project storyline is required";
    return errors;
  };

  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  };

  const handleSave = async (data, status = "draft") => {
    try {
      const html = draftToHtml(data.body);
      const projectData = {
        ...data,
        status: status === "published" ? "published" : "draft",
        body: html,
      };
      await mutateAsync(projectData);

      if (status === "published") {
        showNotification("Project published successfully!");
      } else {
        showNotification("Project saved to drafts!");
      }
    } catch (err) {
      showNotification("Error saving project", "error");
    }
  };

  const showNotification = (message, type = "success") => {
    // Implement your notification logic here
  };

  if (isSuccess) {
    setTimeout(() => {
      navigate("/admin/projects");
    }, 2000);
  }

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-semibold text-gray-800">
            Create New Project
          </h1>
          {/* <Button
            onClick={() => setPreviewMode(!previewMode)}
            className="flex items-center gap-2 text-gray-600 hover:text-blue-600"
          >
            <Eye size={20} />
            {previewMode ? "Edit Mode" : "Preview"}
          </Button> */}
        </div>

        {error && (
          <div className="mb-6 p-4 bg-red-50 border border-red-200 rounded-lg flex items-center gap-2 text-red-700">
            <AlertCircle size={20} />
            <span>Error: {error.message}</span>
          </div>
        )}

        <Form
          enableReinitialize
          initialValues={{
            name: "",
            description: "",
            liveUrl: "",
            githubUrl: "",
            image: "",
            body: "",
          }}
          validate={validateForm}
          onSubmit={(value) => handleSave(value, "published")}
          onChange={(values) => setFormData(values)}
        >
          <Form.Element>
            <div className={`space-y-6 ${previewMode ? "hidden" : "block"}`}>
              <Form.Field.Input
                name="name"
                label="Project Name"
                placeholder="Enter your project name"
                className="focus:ring-2 focus:ring-blue-500"
              />

              <Form.Field.Input
                name="description"
                label="Project Description"
                placeholder="Brief description of your project"
                className="focus:ring-2 focus:ring-blue-500"
              />

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="flex flex-col">
                  <Form.Field.Input
                    name="liveUrl"
                    label="Live URL"
                    placeholder="https://your-project.com"
                    icon={<Globe size={18} />}
                  />
                </div>
                <div className="flex flex-col">
                  <Form.Field.Input
                    name="githubUrl"
                    label="Github URL"
                    placeholder="https://github.com/username/repo"
                    icon={<Github size={18} />}
                  />
                </div>
              </div>

              <Form.Field.FileInput
                name="image"
                label="Project Image"
                accept="image/*"
                preview
              />

              <Form.Field.TextEditor
                name="body"
                label="Project Storyline"
                className="min-h-[300px]"
              />
            </div>

            {previewMode && formData && (
              <div className="prose max-w-none">
                <h1>{formData.name}</h1>
                <p className="text-gray-600">{formData.description}</p>
                {formData.image && (
                  <img
                    src={URL.createObjectURL(formData.image)}
                    alt={formData.name}
                    className="my-4 rounded-lg"
                  />
                )}
                <div
                  dangerouslySetInnerHTML={{
                    __html: draftToHtml(formData.body),
                  }}
                />
              </div>
            )}

            {isSuccess && (
              <div className="p-4 bg-green-50 border border-green-200 rounded-lg text-green-700 my-4">
                Project uploaded successfully! Redirecting to project list...
              </div>
            )}

            <div className="flex gap-4 mt-6">
              <Button
                type="submit"
                isWorking={isLoading}
                className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded-lg flex items-center gap-2"
              >
                <Save size={20} />
                Publish
              </Button>
              <Button
                onClick={() => handleSave(formData, "draft")}
                isWorking={isLoading}
                className="border border-gray-300 hover:bg-gray-50 text-gray-700 px-6 py-2 rounded-lg"
              >
                Save to draft
              </Button>
            </div>
          </Form.Element>
        </Form>
      </div>
    </div>
  );
};

export default CreateProject;
