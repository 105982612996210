import { useState, useEffect } from "react";
import { Menu, X, Terminal, Code } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { useDevMode } from "../context/devModeContext";

const Header = () => {
  const { isDevMode, toggleDevMode } = useDevMode();
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleDownload = async (e) => {
    e.preventDefault();
    if (window.confirm("Would you like to download my resume?")) {
      try {
        const response = await fetch("/resume.pdf");
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "ajayi-damilola_resume.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Download failed:", error);
      }
    }
  };

  const navLinks = [
    { label: "About", devLabel: "$ whoami", url: "/#about" },
    { label: "Projects", devLabel: "$ ls -la projects", url: "/#projects" },
    { label: "Blog", devLabel: "$ cat blog/*.md", url: "/blogs" },
    { label: "Contact", devLabel: "$ ping contact", url: "/#contact" },
    {
      label: "Resume",
      devLabel: "$ curl -O resume.pdf",
      url: "#",
      onClick: handleDownload,
    },
  ];

  useEffect(() => {
    const handleScroll = () => setIsScrolled(window.scrollY > 20);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <header
      className={`fixed w-full z-50 transition-all duration-300 ${
        isScrolled
          ? "py-3 bg-gray-900/90 backdrop-blur-sm shadow-cyber"
          : "py-4 bg-transparent"
      }`}
    >
      <div className="container mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between">
          <motion.a
            href="/"
            className="text-xl sm:text-2xl font-bold font-mono"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-blue-500 animate-text-glow">
              {isDevMode ? "> skyreal_DEV" : "DAMI"}
            </span>
            <span className="ml-1 sm:ml-2 text-green-400 animate-cursor-blink">
              _
            </span>
          </motion.a>

          <div className="flex items-center gap-2 sm:gap-4">
            <button
              onClick={toggleDevMode}
              className="hidden md:flex items-center gap-2 p-2 rounded-lg hover:bg-gray-800 transition-colors group min-tap-target-size"
              aria-label={`Switch to ${
                isDevMode ? "normal" : "developer"
              } mode`}
            >
              {isDevMode ? (
                <>
                  <Terminal className="w-5 h-5 text-green-400 animate-pulse" />
                  <span className="text-sm text-green-400 font-mono min-w-[160px] text-right">
                    [DEV MODE ACTIVE]
                  </span>
                </>
              ) : (
                <>
                  <Code className="w-5 h-5 text-blue-400" />
                  <span className="text-sm text-gray-400 group-hover:text-blue-400 transition-colors font-mono min-w-[160px]">
                    ACTIVATE_TERMINAL
                  </span>
                </>
              )}
            </button>

            <button
              onClick={() => setIsOpen(!isOpen)}
              className="md:hidden p-2 rounded-lg hover:bg-gray-800 transition-colors min-tap-target-size"
              aria-label="Toggle navigation"
            >
              {isOpen ? (
                <X className="w-6 h-6 text-red-400" />
              ) : (
                <Menu className="w-6 h-6 text-green-400" />
              )}
            </button>
          </div>
        </div>

        <AnimatePresence>
          {isOpen && (
            <motion.nav
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              className="md:hidden bg-gray-900 border-t border-green-400/20 mt-4 overflow-hidden"
            >
              <motion.ul className="container mx-auto px-4 py-4 space-y-4 font-mono divide-y divide-green-400/20">
                {navLinks.map((link) => (
                  <motion.li
                    key={link.label}
                    className="border-l-2 border-green-400 pl-4 pt-2 pb-2"
                  >
                    <a
                      href={link.url}
                      onClick={(e) => {
                        setIsOpen(false);
                        if (link.onClick) link.onClick(e);
                      }}
                      className={`block text-base sm:text-lg ${
                        link.label === "Resume"
                          ? "text-green-400 hover:text-green-300"
                          : "text-gray-400 hover:text-green-400"
                      } truncate max-w-[240px] sm:max-w-none`}
                    >
                      {isDevMode ? (
                        <span className="flex items-center gap-2">
                          <span className="text-blue-400">➜</span>
                          {link.devLabel}
                        </span>
                      ) : (
                        link.label
                      )}
                    </a>
                  </motion.li>
                ))}
              </motion.ul>
            </motion.nav>
          )}
        </AnimatePresence>

        <nav className="hidden md:block">
          <ul className="flex flex-wrap justify-end gap-x-4 gap-y-2 mt-4 font-mono ml-auto">
            {navLinks.map((link) => (
              <li key={link.label} className="relative group">
                <a
                  href={link.url}
                  onClick={link.onClick}
                  className={`px-2 sm:px-3 py-2 text-sm sm:text-base ${
                    link.label === "Resume"
                      ? "text-black bg-green-400 hover:bg-green-300 px-3 sm:px-4 py-2 rounded-lg"
                      : "text-gray-400 hover:text-green-400"
                  } transition-colors duration-200`}
                >
                  {isDevMode ? (
                    <span className="flex items-center gap-1 sm:gap-2">
                      <span className="text-blue-400">➜</span>
                      {link.devLabel}
                    </span>
                  ) : (
                    link.label
                  )}
                </a>
                <div className="absolute bottom-0 left-0 w-0 h-px bg-green-400 transition-all group-hover:w-full" />
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
