import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(["file", "text"]),
  onChange: PropTypes.func,
};

const defaultProps = {
  className: "",
  type: "text",
  onChange: () => {},
};

const Input = ({ className, type, onChange, ...othersProps }) => {
  const handleChange = (event) => {
    if (event.target) {
      onChange(event.target.value, event);
    }
  };
  return (
    <div className={"mb-6 w-full"}>
      <input
        type={type}
        onChange={handleChange}
        className={`block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base ${className}`}
        {...othersProps}
      />
    </div>
  );
};

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
