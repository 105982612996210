import React from "react";
import { Outlet } from "react-router-dom";
import AdminProjectsList from "./projectList";

const AdminProjectPage = () => {
  return (
    <div className="max-w-6xl mx-auto px-4">
      <div className="flex flex-col items-center p-6">
        {/* Project Icon */}
        <div className="mb-6">
          <AddProjectSvg />
        </div>

        {/* Add Project Button */}
        <a href="/admin/project/new">
          <button className="bg-blue-500 hover:bg-blue-600 text-white rounded-lg px-6 py-3 transition-colors duration-200 flex items-center space-x-2 shadow-sm">
            <span>Add new project</span>
            <PlusIcon />
          </button>
        </a>
      </div>

      {/* Projects List */}
      <div className="mt-8">
        <AdminProjectsList />
      </div>
      <Outlet />
    </div>
  );
};

const AddProjectSvg = () => {
  return (
    <svg
      className="text-gray-400 hover:text-blue-500 transition-colors duration-200"
      fill="currentColor"
      height="90px"
      width="90px"
      viewBox="0 0 24 24"
      transform="matrix(-1, 0, 0, -1, 0, 0)"
    >
      <path d="M18,24H0V6h6v2H2v14h14v-4H6V0h18v18h-6V24z M8,16h14V2H8V16z M16,13h-2v-3h-3V8h3V5h2v3h3v2h-3V13z" />
    </svg>
  );
};

const PlusIcon = () => (
  <svg
    className="w-5 h-5"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 6v6m0 0v6m0-6h6m-6 0H6"
    />
  </svg>
);

export default AdminProjectPage;
