import { Navigate } from "react-router-dom";
import { api } from "../utils/api";
import Loading from "../component/loading";

const RequireLogin = ({ children }) => {
  const { isSuccess, isError, isLoading } = api.useGet("/auth/authenticate", {
    staleTime: Infinity,
    retry: 0,
  });

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <Navigate to={"/admin/login"} />;
  }

  if (isSuccess) {
    return children;
  }

  // return isLoading ? (
  //   <Loading />
  // ) : isSuccess ? (
  //   children
  // ) : (
  //   <Navigate to={"/admin/login"} />
  // );
};

export default RequireLogin;
