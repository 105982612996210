import React from "react";
import "../../noMoreTailwind.css";

const EditorContent = ({ content }) => {
  return content ? (
    <div
      className="no-more-tailwind"
      dangerouslySetInnerHTML={{ __html: content }}
    />
  ) : (
    <div>null</div>
  );
};

export default EditorContent;
