import React from "react";
import { Outlet } from "react-router-dom";
import { LogOut } from "lucide-react";
import Footer from "../component/footer";

const AdminHome = () => {
  const isActive = (path) => {
    return window.location.pathname === path;
  };

  const handleNavigation = (path) => {
    window.location.pathname = path;
  };

  const handleLogout = () => {
    localStorage.removeItem("access_token");
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <nav className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            <h1 className="text-2xl font-bold text-blue-600 tracking-tight hover:text-blue-700 transition-colors">
              DAMI
            </h1>

            <a
              href="/admin/login"
              onClick={handleLogout}
              className="p-2 rounded-lg hover:bg-gray-100 transition-colors group"
              title="Logout"
            >
              <LogOut
                className="h-6 w-6 text-gray-600 group-hover:text-blue-600 transition-colors"
                strokeWidth={1.5}
              />
            </a>
          </div>
        </div>
      </nav>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="mt-8 flex justify-center space-x-4">
          <NavigationButton
            isActive={isActive("/admin/projects")}
            onClick={() => handleNavigation("/admin/projects")}
            label="Projects"
          />
          <NavigationButton
            isActive={isActive("/admin/blogs")}
            onClick={() => handleNavigation("/admin/blogs")}
            label="Blogs"
          />
        </div>

        <main className="mt-8">
          <Outlet />
        </main>
      </div>

      <div className="mt-auto">
        <Footer />
      </div>
    </div>
  );
};

const NavigationButton = ({ isActive, onClick, label }) => (
  <button
    className={`
      px-6 py-3 rounded-lg font-medium
      transition-all duration-200 transform
      hover:scale-105 hover:shadow-md
      focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50
      ${
        isActive
          ? "bg-blue-500 text-white shadow-md"
          : "bg-white text-gray-700 hover:bg-blue-500 hover:text-white"
      }
    `}
    onClick={onClick}
  >
    {label}
  </button>
);

export default AdminHome;
