import React from "react";
import Header from "../component/header";
import { api } from "../utils/api";
import { motion } from "framer-motion";
import { Calendar } from "lucide-react";

const BlogPage = () => {
  const {
    data: blogPosts,
    isSuccess,
    isLoading,
  } = api.useGet("/blog", {
    staleTime: Infinity,
  });

  return (
    <main className="min-h-screen bg-gray-900 relative overflow-hidden">
      {/* Cyberpunk background effects */}
      <div className="absolute inset-0 bg-[linear-gradient(to_right,#1a1a1a_1px,transparent_1px),linear-gradient(to_bottom,#1a1a1a_1px,transparent_1px)] bg-[size:4rem_4rem] [mask-image:radial-gradient(ellipse_60%_50%_at_50%_0%,#000_70%,transparent_100%)] opacity-25" />
      <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-green-400/10 to-transparent pointer-events-none" />

      <Header />
      <div className="container mx-auto px-4 sm:px-6 py-16 sm:py-24 relative">
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-3xl sm:text-4xl md:text-5xl font-bold font-mono bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-blue-500 animate-text-glow text-center mb-12"
        >
          Thoughts & Insights
        </motion.h1>

        {isLoading && (
          <div className="flex flex-col items-center justify-center gap-4 min-h-[400px] bg-gray-800/50 rounded-xl border-2 border-dashed border-green-400/20">
            <div className="space-y-2 text-sm text-cyan-400 font-mono text-center">
              <p className="text-green-400 animate-pulse">
                $ curl -X GET https://api.skyreal.dev/blog
              </p>
              <div className="w-64 h-2 bg-gray-800 rounded-full overflow-hidden">
                <div className="w-full h-full bg-gradient-to-r from-green-400 to-blue-500 animate-progress" />
              </div>
              <p className="text-green-400 animate-pulse">
                [200] Decrypting knowledge stream...
              </p>
            </div>
          </div>
        )}

        {isSuccess && (
          <div className="max-w-4xl mx-auto space-y-8">
            {blogPosts.data.map((post, index) => (
              <BlogPost key={post._id} {...post} index={index} />
            ))}
          </div>
        )}
      </div>

      {/* Glitch effect SVG filter */}
      <svg className="hidden">
        <filter id="glitch">
          <feTurbulence
            type="fractalNoise"
            baseFrequency="0.8"
            numOctaves="4"
          />
          <feDisplacementMap in="SourceGraphic" scale="8" />
        </filter>
      </svg>
    </main>
  );
};

const BlogPost = ({
  title,
  author,
  authorImage,
  coverImage,
  description,
  body,
  createdAt: time,
  _id: postId,
  index,
}) => {
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  }).format(new Date(time));

  const truncateText = (text, limit) => {
    if (!text) return "";
    const words = text.split(" ");
    return (
      words.slice(0, limit).join(" ") + (words.length > limit ? "..." : "")
    );
  };

  const parseBodyPreview = (htmlBody) => {
    if (!htmlBody) return "";
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlBody, "text/html");
    const firstParagraph = doc.querySelector("p")?.textContent || "";
    return truncateText(firstParagraph, 15);
  };

  return (
    <motion.article
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      className="group bg-gray-800/50 backdrop-blur-sm rounded-xl border-2 border-green-400/20 hover:border-green-400/40 overflow-hidden hover:shadow-cyber transition-all duration-300 relative"
    >
      <div className="relative overflow-hidden group-hover:[filter:url(#glitch)]">
        <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent opacity-60" />
        <img
          src={coverImage}
          alt={title}
          className="w-full h-64 object-cover transform group-hover:scale-105 transition-transform duration-300"
        />
      </div>

      <div className="p-8 space-y-6">
        <div className="flex items-center justify-between">
          <h3 className="text-2xl font-bold font-mono text-gray-200 flex items-center gap-2">
            <Calendar className="w-4 h-4 text-green-400" />
            {formattedDate}
          </h3>
          <div className="text-sm text-green-400 font-mono">
            <span className="text-blue-400">.</span> {title}
          </div>
        </div>

        <p className="text-gray-400 font-mono line-clamp-3">
          {description || parseBodyPreview(body)}
        </p>

        <div className="flex justify-between gap-4">
          <a
            href={`/blogs/${postId}`}
            className="flex items-center justify-center gap-2 px-6 py-3 border-2 border-green-400/20 hover:border-green-400 text-green-400 rounded-lg font-mono hover:shadow-cyber transition-all duration-300"
          >
            <span className="text-blue-400">$</span>
            view post
          </a>
        </div>
      </div>
    </motion.article>
  );
};

export default BlogPage;
