import Footer from "../component/footer";
import Header from "../component/header";
import Projects from "../project";
import About from "./about";
import Contact from "./contact";
import Hero from "./hero";

function Home() {
  return (
    <>
      <Header />
      <Hero />
      <About />
      <Projects />
      <Contact />
      <Footer />
    </>
  );
}

export default Home;
