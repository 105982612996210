import Home from "./home";
import "./index.css";
import { Routes, Route, Navigate } from "react-router-dom";
import ProjectStudy from "./project/study";
import AdminHome from "./admin";
import Login from "./admin/login";
import RequireLogin from "./admin/requireLogin";
import CreateProject from "./admin/project/createProject";
import AdminProject from "./admin/project";
import AdminBlog from "./admin/blog";
import BlogPage from "./blog";
import BlogDetails from "./blog/blogDetail";
import CreateBlog from "./admin/blog/createBlog";
import EditProject from "./admin/project/editProject";
import EditBloPost from "./admin/blog/editBlogPost";
import { DevModeProvider } from "./context/devModeContext";

function App() {
  return (
    <DevModeProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects/:id" element={<ProjectStudy />} />
        <Route path="/blogs" element={<BlogPage />} />
        <Route path="/blogs/:id" element={<BlogDetails />} />
        <Route path="/admin/*" element={<AdminRoutes />} />
        <Route path="/admin/login" element={<Login />} />
      </Routes>
    </DevModeProvider>
  );
}

function AdminRoutes() {
  return (
    <RequireLogin>
      <Routes>
        <Route path="" element={<AdminHome />}>
          <Route path="" element={<DefaultAdminHome />} />
          <Route path="projects" element={<AdminProject />} />
          <Route path="blogs" element={<AdminBlog />} />
          <Route path="blog/:id/edit" element={<EditBloPost />} />
          <Route path="blog/new" element={<CreateBlog />} />
          <Route path="project/:id/edit" element={<EditProject />} />
          <Route path="project/new" element={<CreateProject />} />
        </Route>
      </Routes>
    </RequireLogin>
  );
}

function DefaultAdminHome() {
  return <Navigate to="/admin/projects" replace />;
}

export default App;
