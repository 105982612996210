import React from "react";
import { Outlet } from "react-router-dom";
import AdminBlogList from "./adminBlogList";

const AdminBlogPage = () => {
  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col items-center p-10 text-center">
        <AddBlogSvg />
        <a href="/admin/blog/new" className="inline-block">
          <button className="rounded-lg bg-gray-200 hover:bg-gray-300 text-base px-6 py-4 mt-2 inline-flex items-center">
            <span className="mr-2">Add new blog</span>
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
          </button>
        </a>
      </div>
      <AdminBlogList />
      <Outlet />
    </div>
  );
};

const AddBlogSvg = () => {
  return (
    <svg
      className="cursor-pointer duration-300 hover:scale-105"
      fill="#c2c2c2"
      height="90px"
      width="90px"
      viewBox="0 0 24 24"
      stroke="#c2c2c2"
      transform="matrix(-1, 0, 0, -1, 0, 0)"
    >
      <path d="M18,24H0V6h6v2H2v14h14v-4H6V0h18v18h-6V24z M8,16h14V2H8V16z M16,13h-2v-3h-3V8h3V5h2v3h3v2h-3V13z" />
    </svg>
  );
};

export default AdminBlogPage;
