import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Terminal, Code2, Database, ChevronDown, Cpu } from "lucide-react";
import { useDevMode } from "../context/devModeContext";

const Hero = () => {
  const { isDevMode } = useDevMode();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const features = [
    {
      icon: <Terminal className="w-6 h-6" />,
      title: "Backend Mastery",
      devTitle: "sys.kernel.optimize",
      subtitle: "Node.js & TypeScript Expert",
      devSubtitle: "runtime.performance = 100",
      color: "text-green-400",
    },
    {
      icon: <Code2 className="w-6 h-6" />,
      title: "Clean Architecture",
      devTitle: "clean.code.execute",
      subtitle: "Scalable Solutions",
      devSubtitle: "maintainability++",
      color: "text-blue-400",
    },
    {
      icon: <Database className="w-6 h-6" />,
      title: "Database Design",
      devTitle: "db.sharding.init",
      subtitle: "Performance Optimization",
      devSubtitle: "scaling.factor = infinity",
      color: "text-cyan-400",
    },
    {
      icon: <Cpu className="w-6 h-6" />,
      title: "System Optimization",
      devTitle: "perf.analyze --deep",
      subtitle: "Performance Tuning",
      devSubtitle: "latency.ms = minimal",
      color: "text-purple-400",
    },
  ];

  return (
    <section className="relative min-h-[100svh] bg-gray-900 overflow-hidden">
      {/* Cyber grid background */}
      <div className="absolute inset-0 bg-[linear-gradient(to_right,#1a1a1a_1px,transparent_1px),linear-gradient(to_bottom,#1a1a1a_1px,transparent_1px)] bg-[size:4rem_4rem] [mask-image:radial-gradient(ellipse_60%_50%_at_50%_0%,#000_70%,transparent_100%)] opacity-25" />

      <div className="container mx-auto px-4 py-24 relative">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 20 }}
          transition={{ duration: 0.8 }}
          className="grid lg:grid-cols-2 gap-12 items-center"
        >
          <div className="space-y-8">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.3 }}
              className="space-y-6"
            >
              <h1 className="text-5xl md:text-7xl font-bold font-mono">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-blue-500 animate-text-glow">
                  {isDevMode ? (
                    <span>
                      {">"} init skyreal.dev
                      <span className="ml-2 animate-cursor-blink">_</span>
                    </span>
                  ) : (
                    "Engineering Digital Excellence"
                  )}
                </span>
              </h1>
              <p className="text-xl text-gray-400 font-mono leading-relaxed">
                {isDevMode ? (
                  <>
                    <span className="text-blue-400">$</span> Executing
                    high-performance code sequences...
                    <br />
                    <span className="text-blue-400">$</span> Optimizing system
                    architecture...
                    <br />
                    <span className="text-blue-400">$</span> Building scalable
                    solutions...
                  </>
                ) : (
                  "I build elegant, efficient backend solutions that solve real business problems. My focus is on creating optimized systems that make a difference."
                )}
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.6 }}
              className="flex flex-col sm:flex-row gap-4 font-mono"
            >
              <a
                href="#projects"
                className="px-8 py-4 bg-green-400 hover:bg-green-500 text-black rounded-lg font-semibold shadow-cyber transition-all duration-300 text-center group"
              >
                {isDevMode ? (
                  <span className="flex items-center justify-center gap-2">
                    <span className="text-black">$</span>
                    ./explore-projects
                  </span>
                ) : (
                  "Explore My Work"
                )}
              </a>
              <a
                href="#contact"
                className="px-8 py-4 border border-green-400/50 hover:border-green-400 text-green-400 rounded-lg font-semibold transition-all duration-300 text-center group hover:shadow-cyber"
              >
                {isDevMode ? (
                  <span className="flex items-center justify-center gap-2">
                    <span className="text-blue-400">$</span>
                    ping --contact
                  </span>
                ) : (
                  "Let's Connect"
                )}
              </a>
            </motion.div>
          </div>

          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.3 }}
            className="grid grid-cols-2 gap-4"
          >
            {features.map((feature, index) => (
              <motion.div
                key={feature.title}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3 + index * 0.1 }}
                className="p-6 bg-gray-800/50 backdrop-blur-sm rounded-xl border border-green-400/20 hover:shadow-cyber transition-all duration-300 group"
              >
                <div className={`${feature.color}`}>{feature.icon}</div>
                <h3 className="mt-4 font-mono font-semibold text-lg text-gray-200">
                  {isDevMode ? (
                    <span className="flex items-center gap-2">
                      <span className="text-blue-400">$</span>
                      {feature.devTitle}
                    </span>
                  ) : (
                    feature.title
                  )}
                </h3>
                <p className="mt-2 text-sm text-gray-400 font-mono">
                  {isDevMode ? (
                    <span className="text-cyan-300">{feature.devSubtitle}</span>
                  ) : (
                    feature.subtitle
                  )}
                </p>
              </motion.div>
            ))}
          </motion.div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 20 }}
          transition={{ delay: 1.2 }}
          className="absolute bottom-8 left-1/2 transform -translate-x-1/2 hidden sm:block"
        >
          <ChevronDown className="w-8 h-8 animate-bounce text-green-400" />
        </motion.div>
      </div>
    </section>
  );
};

export default Hero;
