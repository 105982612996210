import React from "react";
import PropTypes from "prop-types";
import { handleFileUpload } from "../../utils/fileUpload";

const propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
};

const FileInput = ({ className, onChange, ...otherProps }) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleFileChange = async (e) => {
    setLoading(true);
    setError(null);
    const file = e.target.files?.[0];
    if (!file) {
      setLoading(false);
      return;
    }
    try {
      const fileObject = await handleFileUpload(file);
      onChange(fileObject.data.link);
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  };

  return (
    <div className={`mb-6 w-full ${className}`}>
      <input
        type="file"
        onChange={handleFileChange}
        disabled={loading}
        className={`block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base ${loading ? "opacity-50 cursor-not-allowed" : ""}`}
        // {...otherProps}
      />
      {loading && <span className="text-sm text-gray-500">Loading...</span>}
      {error && <span className="text-sm text-red-500">{error}</span>}
    </div>
  );
};

FileInput.propTypes = propTypes;

export default FileInput;
