import { useDevMode } from "../context/devModeContext";
import { motion } from "framer-motion";
import { Terminal, Cpu, Database, Bug, Coffee } from "lucide-react";

const About = () => {
  const { isDevMode } = useDevMode();

  const skills = [
    {
      title: isDevMode ? "Node.js ||> ExpertMode" : "Technical Excellence",
      description: isDevMode
        ? "Master of async/await realms, promise whisperer, and event loop wrangler"
        : "Node.js, TypeScript, and modern backend technologies",
      icon: <Terminal className="w-6 h-6 text-green-400" />,
      devCommand: "npm install @skyreal/expertise --production",
    },
    {
      title: isDevMode ? "DB Sharding.exe" : "Database Mastery",
      description: isDevMode
        ? "PostgreSQL whisperer, MongoDB aggregator, Redis speedrunner"
        : "Proficient in PostgreSQL, MongoDB, and Redis with focus on performance optimization",
      icon: <Database className="w-6 h-6 text-blue-400" />,
      devCommand: "CREATE INDEX ON performance (awesomeness);",
    },
    {
      title: isDevMode ? "AI Integration++" : "AI Integration",
      description: isDevMode
        ? "Prompt engineer by day, neural network dreamer by night"
        : "Experience with AI integration and prompt engineering, showcased in ResuMatch platform",
      icon: <Cpu className="w-6 h-6 text-purple-400" />,
      devCommand: "train(model).with(creativity=MAX);",
    },
    {
      title: isDevMode ? "Bug Exterminator v2.3" : "Problem Solving",
      description: isDevMode
        ? "Professional bug hunter - 99.9% crash-free guarantee*"
        : "Strong focus on clean architecture and solving complex business logic challenges",
      icon: <Bug className="w-6 h-6 text-red-400" />,
      devCommand: "DEBUG_MODE=skyreal fix --all-the-things",
    },
  ];

  return (
    <section className="py-24 bg-white dark:bg-[#0a0a0a]" id="about">
      <div className="container mx-auto px-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="max-w-3xl mx-auto text-center space-y-8"
        >
          <h2 className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-indigo-600 to-purple-600 dark:from-green-400 dark:to-cyan-400">
            {isDevMode ? (
              <span className="font-mono">
                {">"} backend_engineer --precision --scalability
                <span className="ml-2 text-green-400 animate-cursor-blink">
                  _
                </span>
              </span>
            ) : (
              "Ajayi Damilola Samuel"
            )}
          </h2>
          <p className="text-xl text-gray-600 dark:text-gray-300 leading-relaxed">
            {isDevMode ? (
              <>
                <span className="text-green-400">/*</span>
                <br />
                <span className="text-cyan-300">
                  API artisan | Database whisperer
                  <br />
                  Specialization: Making servers sing
                  <br />
                  Current stack: Node.js + TypeScript + PostgreSQL
                  <br />
                  Philosophy: If it can't handle 3am traffic, it's not done
                </span>
                <br />
                <span className="text-green-400">*/</span>
              </>
            ) : (
              <>
                I build the backbone that powers digital experiences. My focus
                is creating backend systems that are:
                <br />
                <br />
                <span className="font-semibold">• Predictably reliable</span> -
                Even during midnight launches
                <br />
                <span className="font-semibold">• Securely scalable</span> -
                From MVP to enterprise-grade
                <br />
                <span className="font-semibold">• Developer-friendly</span> -
                Because clean APIs deserve clean docs
              </>
            )}
          </p>

          {isDevMode && (
            <motion.div
              initial={{ scale: 0 }}
              whileInView={{ scale: 1 }}
              className="mt-8 p-4 bg-gray-900 rounded-lg border border-green-400/20 shadow-cyber"
            >
              <div className="flex items-center gap-3 text-green-400 font-mono text-sm">
                <Terminal className="w-5 h-5 text-green-400/80" />
                <span className="dark:text-green-300">
                  Current project: Payment gateway optimization
                  <br />
                  Recent deploy: Auth microservice v2.3
                  <br />
                  Server status:{" "}
                  {Math.random() > 0.5 ? "Nominal" : "Coffee required"}
                </span>
              </div>
            </motion.div>
          )}

          <div className="grid md:grid-cols-2 gap-4 mt-12">
            {skills.map((skill, index) => (
              <motion.div
                key={skill.title}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="p-6 bg-gray-50 dark:bg-gray-900 rounded-xl hover:shadow-cyber dark:hover:border-green-400/20 transition-all duration-300 group relative overflow-hidden border border-transparent dark:border-gray-800"
              >
                <div className="flex items-start gap-4">
                  <div className="p-2 bg-gray-200 dark:bg-gray-800 rounded-lg">
                    {skill.icon}
                  </div>
                  <div className="flex-1">
                    <h3 className="font-bold text-xl mb-2 dark:text-gray-200">
                      {skill.title}
                      {isDevMode && (
                        <span className="ml-2 text-green-400 text-sm animate-pulse">
                          [ACTIVE]
                        </span>
                      )}
                    </h3>
                    {isDevMode ? (
                      <div className="font-mono text-sm text-gray-400 dark:text-cyan-300 bg-gray-900 p-3 rounded-lg border border-green-400/20">
                        <span className="text-blue-400">$</span>{" "}
                        <span className="text-green-400">
                          {skill.devCommand}
                        </span>
                        <br />
                        <span className="text-gray-500 dark:text-gray-600">
                          // {skill.description}
                        </span>
                      </div>
                    ) : (
                      <p className="text-gray-600 dark:text-gray-400">
                        {skill.description}
                      </p>
                    )}
                  </div>
                </div>
              </motion.div>
            ))}
          </div>

          {isDevMode && (
            <motion.div
              initial={{ scale: 0 }}
              whileInView={{ scale: 1 }}
              className="mt-8 p-4 bg-gray-900 rounded-lg border border-green-400/20 shadow-cyber"
            >
              <div className="flex items-center gap-3 text-green-400 font-mono">
                <Coffee className="w-5 h-5 animate-pulse text-green-400/80" />
                <span className="text-sm dark:text-green-300">
                  System Status: Brewing awesomeness (
                  {Math.floor(Math.random() * 100)}%)...
                </span>
              </div>
            </motion.div>
          )}
        </motion.div>
      </div>
    </section>
  );
};

export default About;
