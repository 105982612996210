import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { Calendar, Clock, Share2, BookmarkPlus } from "lucide-react";
import Header from "../../component/header";
import Footer from "../../component/footer";
import { api } from "../../utils/api";
import EditorContent from "../../component/EditorContent";
import { useDevMode } from "../../context/devModeContext";

function BlogDetail() {
  const paramId = useParams().id;
  const { data, isLoading, isSuccess } = api.useGet(`/blog/${paramId}`);
  const { isDevMode } = useDevMode();

  if (isLoading) {
    return (
      <div className="min-h-screen relative bg-gray-900">
        {/* Cyberpunk background effects */}
        <div className="absolute inset-0 bg-[linear-gradient(to_right,#1a1a1a_1px,transparent_1px),linear-gradient(to_bottom,#1a1a1a_1px,transparent_1px)] bg-[size:4rem_4rem] [mask-image:radial-gradient(ellipse_60%_50%_at_50%_0%,#000_70%,transparent_100%)] opacity-25" />
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-green-400/10 to-transparent pointer-events-none" />

        <Header />
        <div className="container mx-auto px-4 relative">
          <div className="flex flex-col items-center justify-center min-h-[400px]">
            <div className="space-y-2 text-sm text-cyan-400 font-mono text-center">
              <p className="text-green-400 animate-pulse">
                $ curl -X GET https://api.skyreal.dev/blog/{paramId}
              </p>
              <div className="w-64 h-2 bg-gray-800 rounded-full overflow-hidden">
                <div className="w-full h-full bg-gradient-to-r from-green-400 to-blue-500 animate-progress" />
              </div>
              <p className="text-green-400 animate-pulse">
                [200] Decrypting knowledge stream...
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  if (isSuccess) {
    const {
      coverImage: image,
      description,
      title,
      body,
      createdAt,
      readingTime = "5 min",
    } = data.data;
    console.log("🚀 ~ BlogDetail ~ image:", image);

    const formattedDate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    }).format(new Date(createdAt));

    return (
      <div className="min-h-screen relative bg-gray-900">
        {/* Cyberpunk background effects */}
        <div className="absolute inset-0 bg-[linear-gradient(to_right,#1a1a1a_1px,transparent_1px),linear-gradient(to_bottom,#1a11a1_1px,transparent_1px)] bg-[size:4rem_4rem] [mask-image:radial-gradient(ellipse_60%_50%_at_50%_0%,#000_70%,transparent_100%)] opacity-25" />
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-green-400/10 to-transparent pointer-events-none" />

        <Header />
        <article className="container mx-auto px-4 relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="relative z-10"
          >
            <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold font-mono mb-8 text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-500">
              {isDevMode ? (
                <span className="flex items-center gap-2">
                  <span className="text-blue-400">$</span>
                  {title}
                </span>
              ) : (
                title
              )}
            </h1>

            <div className="flex items-center gap-4 mb-8 text-sm text-gray-400">
              <div className="flex items-center gap-2">
                <Calendar className="w-4 h-4 text-green-400" />
                {formattedDate}
              </div>
              <div className="flex items-center gap-2">
                <Clock className="w-4 h-4 text-green-400" />
                {readingTime} read
              </div>
            </div>

            {isDevMode && (
              <div className="mb-8 p-4 bg-gray-800/50 rounded-lg border-2 border-green-400/20">
                <div className="text-sm text-cyan-400 font-mono">
                  <p>🔍 Debug info:</p>
                  <p>Post ID: {paramId}</p>
                  <p>Words: {body?.length}</p>
                </div>
              </div>
            )}

            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="relative overflow-hidden rounded-xl border-2 border-green-400/20 mb-8"
            >
              <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent opacity-60" />
              <img
                className="w-full h-64 sm:h-96 object-cover"
                src={image}
                alt={title}
              />
              {isDevMode && (
                <div className="absolute top-4 right-4 bg-green-400/20 text-green-400 text-xs px-3 py-1.5 rounded-full font-mono flex items-center gap-2 animate-pulse-slow">
                  <div className="w-2 h-2 bg-green-400 rounded-full shadow-glow" />
                  STATUS:LIVE
                </div>
              )}
            </motion.div>

            {description && (
              <p className="text-gray-400 font-mono mb-8 line-clamp-3">
                {description}
              </p>
            )}

            <div className="flex justify-center gap-4 mb-12">
              <button className="flex items-center gap-2 px-6 py-3 border-2 border-green-400/20 hover:border-green-400/40 text-green-400 font-mono rounded-lg transition-all duration-300 hover:shadow-cyber">
                <Share2 className="w-4 h-4" />
                Share
              </button>
              <button className="flex items-center gap-2 px-6 py-3 border-2 border-green-400/20 hover:border-green-400/40 text-green-400 font-mono rounded-lg transition-all duration-300 hover:shadow-cyber">
                <BookmarkPlus className="w-4 h-4" />
                Save
              </button>
            </div>

            <EditorContent content={body} className="font-mono text-gray-300" />
          </motion.div>
        </article>
        <Footer />
        {/* Glitch effect SVG filter */}
        <svg className="hidden">
          <filter id="glitch">
            <feTurbulence
              type="fractalNoise"
              baseFrequency="0.8"
              numOctaves="4"
            />
            <feDisplacementMap in="SourceGraphic" scale="8" />
          </filter>
        </svg>
      </div>
    );
  }
}

export default BlogDetail;
