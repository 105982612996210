import React, { useState } from "react";
import {
  Edit2,
  Search,
  Trash2,
  CheckSquare,
  AlertCircle,
  ArrowDownToLine,
} from "lucide-react";
import { api } from "../../utils/api";
import Button from "../../component/button";
import Loading from "../../component/loading";
import ProjectCard from "./projectCard";

const AdminProjectList = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState("newest");
  const [selectedProjects, setSelectedProjects] = useState([]);

  const {
    data: projects,
    isLoading,
    isSuccess,
  } = api.useGet("/project", { staleTime: Infinity });

  const urlParams = new URLSearchParams(window.location.search);
  const filter = urlParams.get("filter");

  const handleSort = (projects) => {
    switch (sortBy) {
      case "newest":
        return [...projects].sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
      case "oldest":
        return [...projects].sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        );
      case "name":
        return [...projects].sort((a, b) => a.name.localeCompare(b.name));
      default:
        return projects;
    }
  };

  const handleSearch = (projects) => {
    if (!searchTerm) return projects;
    return projects.filter(
      (project) =>
        project.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        project.description.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  let filteredProjects;
  if (isSuccess) {
    filteredProjects = filter
      ? projects.data.filter((project) => project.state === filter)
      : projects.data;
    filteredProjects = handleSort(handleSearch(filteredProjects));
  }

  const handleBulkAction = async (action) => {
    if (!selectedProjects.length) return;

    switch (action) {
      case "publish":
        break;
      case "draft":
        break;
      case "delete":
        break;
      case "export":
        const exportData = selectedProjects.map((id) => {
          const project = filteredProjects.find((p) => p._id === id);
          return {
            name: project.name,
            description: project.description,
            state: project.state,
            liveUrl: project.liveUrl,
          };
        });
        const blob = new Blob([JSON.stringify(exportData, null, 2)], {
          type: "application/json",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "projects-export.json";
        a.click();
        break;
      default:
        break;
    }
  };

  return (
    <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="mb-8">
        <div className="flex flex-col sm:flex-row justify-between gap-4">
          <div className="relative flex-1 max-w-md">
            <input
              type="text"
              placeholder="Search projects..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
            <Search
              className="absolute left-3 top-2.5 text-gray-400"
              size={20}
            />
          </div>

          <div className="flex gap-2">
            <select
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              className="border rounded-lg px-3 py-2 focus:ring-2 focus:ring-blue-500"
            >
              <option value="newest">Newest First</option>
              <option value="oldest">Oldest First</option>
              <option value="name">By Name</option>
            </select>
          </div>
        </div>

        {selectedProjects.length > 0 && (
          <div className="mt-4 p-3 bg-gray-50 rounded-lg flex items-center justify-between">
            <span className="text-sm text-gray-600">
              {selectedProjects.length} projects selected
            </span>
            <div className="flex gap-2">
              <Button
                onClick={() => handleBulkAction("publish")}
                className="text-sm bg-green-50 text-green-600 hover:bg-green-100"
              >
                <CheckSquare size={16} className="mr-1" />
                Publish All
              </Button>
              <Button
                onClick={() => handleBulkAction("draft")}
                className="text-sm bg-yellow-50 text-yellow-600 hover:bg-yellow-100"
              >
                <Edit2 size={16} className="mr-1" />
                Draft All
              </Button>
              <Button
                onClick={() => handleBulkAction("export")}
                className="text-sm bg-blue-50 text-blue-600 hover:bg-blue-100"
              >
                <ArrowDownToLine size={16} className="mr-1" />
                Export
              </Button>
              <Button
                onClick={() => handleBulkAction("delete")}
                className="text-sm bg-red-50 text-red-600 hover:bg-red-100"
              >
                <Trash2 size={16} className="mr-1" />
                Delete All
              </Button>
            </div>
          </div>
        )}
      </div>

      <div className="mb-8 border-b border-gray-200">
        <nav className="-mb-px flex space-x-8">
          <FilterTab
            href="?filter=published"
            isActive={filter === "published"}
            label="Published"
            count={
              isSuccess
                ? projects.data.filter((p) => p.state === "published").length
                : 0
            }
          />
          <FilterTab
            href="?filter=draft"
            isActive={filter === "draft"}
            label="Ongoing"
            count={
              isSuccess
                ? projects.data.filter((p) => p.state === "draft").length
                : 0
            }
          />
        </nav>
      </div>

      {isLoading && (
        <div className="flex justify-center py-12">
          <Loading />
        </div>
      )}

      {isSuccess && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredProjects.map((project) => (
            <ProjectCard
              key={project._id}
              project={project}
              isSelected={selectedProjects.includes(project._id)}
              onSelect={() => {
                setSelectedProjects((prev) =>
                  prev.includes(project._id)
                    ? prev.filter((id) => id !== project._id)
                    : [...prev, project._id]
                );
              }}
            />
          ))}
        </div>
      )}

      {isSuccess && filteredProjects.length === 0 && (
        <div className="text-center py-12">
          <AlertCircle className="mx-auto h-12 w-12 text-gray-400" />
          <p className="mt-2 text-gray-500 text-lg">No projects found</p>
          <a
            href="/admin/project/new"
            className="mt-4 inline-block text-blue-500 hover:text-blue-600"
          >
            Create your first project
          </a>
        </div>
      )}
    </section>
  );
};

const FilterTab = ({ href, isActive, label, count }) => (
  <a
    href={href}
    className={`
      group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm
      ${
        isActive
          ? "border-blue-500 text-blue-600"
          : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
      }
    `}
  >
    {label}
    {count > 0 && (
      <span
        className={`
        ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium
        ${isActive ? "bg-blue-100 text-blue-600" : "bg-gray-100 text-gray-900"}
      `}
      >
        {count}
      </span>
    )}
  </a>
);

export default AdminProjectList;
