import { Github, Linkedin, Twitter, Mail, Terminal } from "lucide-react";
import { motion } from "framer-motion";
import { useDevMode } from "../context/devModeContext";

const Footer = () => {
  const { isDevMode } = useDevMode();
  const currentYear = new Date().getFullYear();

  const socialLinks = [
    {
      icon: <Github className="w-5 h-5" />,
      href: "https://github.com/skywonda",
      label: "GitHub",
      cmd: "ssh root@github.com/skywonda",
    },
    {
      icon: <Linkedin className="w-5 h-5" />,
      href: "https://www.linkedin.com/in/damilola-ajayi-07b982212",
      label: "LinkedIn",
      cmd: "connect -u ajayi-damilola --protocol=professional",
    },
    {
      icon: <Twitter className="w-5 h-5" />,
      href: "https://twitter.com/skyrealz",
      label: "Twitter",
      cmd: "tweet --user=skyrealz --message='Hacking the mainframe...'",
    },
    {
      icon: <Mail className="w-5 h-5" />,
      href: "mailto:ayobamisamuel85@gmail.com",
      label: "Email",
      cmd: "mailto:root@skyreal.dev",
    },
  ];

  return (
    <footer className="bg-gray-900 border-t border-green-400/20">
      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-flow-row-dense gap-8 md:auto-rows-min md:justify-items-stretch lg:grid-cols-[1fr_1fr_1fr]">
          {/* System Status */}
          <motion.div
            className="text-center md:text-left space-y-3 font-mono lg:justify-self-start"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
          >
            <h3 className="text-green-400 text-lg sm:text-xl mb-2">
              {isDevMode ? "[SYSTEM STATUS]" : "Damilola Ajayi"}
            </h3>
            {isDevMode ? (
              <div className="text-xs sm:text-sm text-gray-400 space-y-1">
                <p className="truncate">
                  {">"} UPTIME: 99.99%{" "}
                  <span className="text-blue-400">[STABLE]</span>
                </p>
                <p>
                  {">"} LATENCY: 42ms{" "}
                  <span className="text-green-400">[OPTIMAL]</span>
                </p>
                <p>
                  {">"} COFFEE:{" "}
                  <span className="text-yellow-400">[OPERATIONAL]</span>
                </p>
              </div>
            ) : (
              <p className="text-gray-400 text-sm sm:text-base">
                Backend Architect & Aspiring Distributed Systems Engineer
              </p>
            )}
          </motion.div>

          {/* Navigation Links */}
          <motion.div
            className="flex flex-wrap justify-center gap-x-4 gap-y-2 font-mono lg:justify-self-center"
            initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
          >
            {["About", "Projects", "Blog", "Contact"].map((link) => (
              <a
                key={link}
                href={`/#${link.toLowerCase()}`}
                className="text-sm sm:text-base text-gray-400 hover:text-green-400 transition-colors group relative px-2 py-1 min-tap-target-size"
              >
                {isDevMode ? (
                  <span className="flex items-center gap-1 sm:gap-2">
                    <span className="text-blue-400">$</span>
                    <span className="truncate">cd {link.toLowerCase()}</span>
                  </span>
                ) : (
                  link
                )}
                <span className="absolute -bottom-1 left-0 w-0 h-px bg-green-400 transition-all group-hover:w-full" />
              </a>
            ))}
          </motion.div>

          {/* Social Links */}
          <motion.div
            className="flex flex-wrap justify-center gap-2 sm:gap-4 lg:justify-self-end"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
          >
            {socialLinks.map((link) => (
              <a
                key={link.label}
                href={link.href}
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 text-gray-400 hover:text-green-400 transition-colors group relative min-tap-target-size"
                aria-label={link.label}
              >
                {isDevMode ? (
                  <div className="font-mono text-xs sm:text-sm flex items-center gap-2">
                    <Terminal className="w-4 h-4 text-blue-400 flex-shrink-0" />
                    <span className="truncate max-w-[120px] xs:max-w-[160px] sm:max-w-[200px]">
                      {link.cmd}
                    </span>
                    <div className="absolute -bottom-1 left-0 w-0 h-px bg-green-400 transition-all group-hover:w-full" />
                  </div>
                ) : (
                  <>
                    {link.icon}
                    <span className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 text-[10px] sm:text-xs text-gray-500 opacity-0 group-hover:opacity-100 transition-opacity">
                      {link.label}
                    </span>
                  </>
                )}
              </a>
            ))}
          </motion.div>
        </div>

        {/* Copyright */}
        <motion.div
          className="mt-8 pt-8 border-t border-green-400/20 text-center font-mono"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
        >
          <p className="text-xs sm:text-sm text-gray-400">
            &copy; {currentYear} SKYREAL SYSTEMS [
            <span className="text-green-400">v1.0.0</span>]
          </p>
          <p className="mt-2 text-[10px] sm:text-xs text-gray-500">
            {isDevMode ? (
              <span className="flex flex-wrap items-center justify-center gap-1 sm:gap-2">
                <span className="text-blue-400">$</span>
                <span>Compiled at {new Date().toLocaleTimeString()} with</span>
                <span className="text-green-400">Node.js v18</span>
              </span>
            ) : (
              "Encrypted connection • End-to-end security"
            )}
          </p>
        </motion.div>
      </div>
    </footer>
  );
};

export default Footer;
