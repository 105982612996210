import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import draftToHtml from "draftjs-to-html";

import Form from "../../component/form";
import Button from "../../component/button";
import { api } from "../../utils/api";

const EditBloPost = () => {
  const navigate = useNavigate();
  const projectId = useParams().id;
  const { data: posts, isLoading } = api.useGet(`/blog/${projectId}`);

  const {
    mutateAsync,
    isSuccess: editSuccess,
    isLoading: isEditing,
  } = api.usePut(`/project/${projectId}`);
  if (isLoading) return <div className="text-center">Loading...</div>;

  const { title, description, body } = posts.data;

  const handleSubmit = async (values) => {
    const hasChanged = Object.keys(values).some(
      (key) => values[key] !== posts.data[key],
    );
    const data = values;
    const html = draftToHtml(data.body);
    data.body = html;
    console.log(body);
    if (hasChanged) {
      await mutateAsync(data);
    }
  };

  if (editSuccess) {
    navigate("/admin/blogs");
  }

  return (
    <div className="flex flex-col w-full items-center">
      <div className="w-[90%]">
        <Form
          enableReinitialize
          initialValues={{ title, description, body }}
          onSubmit={handleSubmit}
        >
          <Form.Element>
            <div className="my-6 border-t-2 border-gray-200"></div>
            <Form.Field.Input name="title" label="Post Title" />
            <Form.Field.Input name="description" label="Post Description" />
            <Form.Field.TextEditor
              name="body"
              label="Post Storyline"
              defaultValue={body}
            />
            <div className="flex p-3 px-0 w-full">
              <Button
                type="submit"
                className="hover:bg-blue-500 text-blue hover:text-white border-blue-500 hover:border-transparent"
                isWorking={isEditing}
              >
                Save
              </Button>
            </div>
          </Form.Element>
        </Form>
      </div>
    </div>
  );
};

export default EditBloPost;
