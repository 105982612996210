import { useDevMode } from "../context/devModeContext";
import { motion } from "framer-motion";
import { Mail, Github, Linkedin, Twitter, Terminal } from "lucide-react";
import { useState } from "react";

const Contact = () => {
  const { isDevMode } = useDevMode();
  const [connectionStatus, setConnectionStatus] = useState(null);

  const handleConnectionTest = () => {
    if (!isDevMode) return;
    setConnectionStatus("testing");
    setTimeout(() => {
      setConnectionStatus("connected");
      setTimeout(() => setConnectionStatus(null), 3000);
    }, 1500);
  };

  const socialLinks = [
    {
      icon: <Github className="w-6 h-6" />,
      href: "https://github.com/skywonda",
      label: "GitHub",
      devLabel: "git clone skyreal-projects --recursive",
      color: "text-green-400",
    },
    {
      icon: <Linkedin className="w-6 h-6" />,
      href: "https://www.linkedin.com/in/damilola-ajayi-07b982212",
      label: "LinkedIn",
      devLabel: "connect --protocol=professional --user=ajayi-damilola",
      color: "text-blue-400",
    },
    {
      icon: <Twitter className="w-6 h-6" />,
      href: "https://twitter.com/skyrealz",
      label: "Twitter",
      devLabel: "tweet --user=skyrealz --message='Hacking the mainframe...'",
      color: "text-cyan-400",
    },
  ];

  return (
    <section className="py-24 bg-gray-900" id="contact">
      <div className="container mx-auto px-4 sm:px-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="max-w-2xl mx-auto text-center space-y-8"
        >
          <h2 className="text-4xl font-bold font-mono bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-blue-500 animate-text-glow">
            {isDevMode ? (
              <span>
                {">"} ./init_connection.sh
                <span className="ml-2 animate-cursor-blink">_</span>
              </span>
            ) : (
              "Let's Build Something Amazing"
            )}
          </h2>

          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            className="text-xl text-gray-400 font-mono leading-relaxed"
          >
            {isDevMode ? (
              <div className="space-y-4">
                <div className="bg-gray-800/50 p-4 rounded-lg border border-green-400/20">
                  <span className="text-blue-400">$</span> system.status --json
                  <div className="mt-2 text-green-400">
                    {"{"}"status": "operational", "coffee_level": "optimal"{"}"}
                  </div>
                </div>
                <button
                  onClick={handleConnectionTest}
                  className="text-sm text-cyan-400 hover:text-cyan-300 font-mono group flex items-center justify-center gap-2 hover:underline"
                >
                  <Terminal className="w-4 h-4 group-hover:animate-pulse" />
                  ping skyreal.dev --full-diagnostics
                </button>
                {connectionStatus && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="text-sm font-mono mt-2"
                  >
                    {connectionStatus === "testing" ? (
                      <span className="text-yellow-400 animate-pulse">
                        [TESTING] Scanning network interfaces...
                      </span>
                    ) : (
                      <span className="text-green-400">
                        [SUCCESS] Connection established! Latency:{" "}
                        {Math.floor(Math.random() * 10 + 30)}ms
                      </span>
                    )}
                  </motion.div>
                )}
              </div>
            ) : (
              "Ready to collaborate on innovative solutions and impactful projects."
            )}
          </motion.div>

          <motion.div
            initial={{ scale: 0.95 }}
            whileInView={{ scale: 1 }}
            className="bg-gray-800/50 backdrop-blur-sm p-8 rounded-xl border border-green-400/20 shadow-cyber space-y-8"
          >
            {/* Email Section */}
            <div className="flex flex-col items-center space-y-6">
              <motion.div
                whileHover={{ scale: 1.05 }}
                className="p-4 bg-gray-900/50 rounded-xl border border-green-400/20 relative group hover:shadow-cyber transition-all duration-300"
              >
                <Mail className="w-8 h-8 text-green-400 group-hover:text-green-300 transition-colors" />
                {isDevMode && (
                  <div className="absolute -top-2 -right-2 bg-green-400 text-xs px-2 py-1 rounded-full font-mono text-black shadow-cyber">
                    SMTP:OK
                  </div>
                )}
              </motion.div>

              <div className="font-mono">
                {isDevMode ? (
                  <div className="bg-gray-900/50 p-4 rounded-lg border border-green-400/20 animate-pulse-slow">
                    <span className="text-blue-400">const</span> contact = {"{"}
                    <br />
                    <span className="ml-4">email: </span>
                    <span className="text-green-400 break-all">
                      "ayobamisamuel85@gmail.com"
                    </span>
                    <br />
                    {"}"}
                  </div>
                ) : (
                  <a
                    href="mailto:ayobamisamuel85@gmail.com"
                    className="text-green-400 hover:text-green-300 transition-colors break-all text-lg"
                  >
                    ayobamisamuel85@gmail.com
                  </a>
                )}
              </div>
            </div>

            {/* Social Links */}
            <div className="pt-8 border-t border-green-400/20">
              <p className="text-gray-400 mb-6 font-mono">
                {isDevMode ? "Available Protocols:" : "Connect with me:"}
              </p>
              <div className="flex flex-wrap justify-center gap-4">
                {socialLinks.map((link) => (
                  <motion.a
                    key={link.label}
                    href={link.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    whileHover={{ y: -2 }}
                    className="p-3 rounded-lg bg-gray-900/50 border border-green-400/20 hover:shadow-cyber transition-all duration-300 group w-full sm:w-auto"
                  >
                    {isDevMode ? (
                      <span className={`font-mono text-sm ${link.color}`}>
                        <span className="text-blue-400">$</span> {link.devLabel}
                      </span>
                    ) : (
                      <div
                        className={`${link.color} hover:scale-110 transition-transform`}
                      >
                        {link.icon}
                      </div>
                    )}
                  </motion.a>
                ))}
              </div>
            </div>

            {/* CTA Button */}
            <motion.div
              whileHover={{ scale: 1.02 }}
              className="pt-8 border-t border-green-400/20"
            >
              <a
                href="mailto:ayobamisamuel85@gmail.com"
                className="inline-block px-8 py-4 bg-green-400 text-black rounded-lg font-mono font-semibold hover:bg-green-300 transition-all duration-300 shadow-cyber group"
              >
                {isDevMode ? (
                  <span className="flex items-center gap-2">
                    <Terminal className="w-5 h-5 animate-pulse" />
                    INIT_CONNECTION()
                  </span>
                ) : (
                  "Let's Connect"
                )}
              </a>
            </motion.div>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default Contact;
