import React from "react";
import { motion } from "framer-motion";
import { ExternalLink, Book, Loader2, Terminal } from "lucide-react";
import { api } from "../utils/api";
import { useDevMode } from "../context/devModeContext";

const Projects = () => {
  const { isDevMode } = useDevMode();
  const {
    data: projectData,
    isSuccess,
    isLoading,
  } = api.useGet("/project?state=published", {
    staleTime: Infinity,
  });

  return (
    <section
      className="py-24 bg-gray-900 relative overflow-hidden"
      id="projects"
    >
      {/* Cyberpunk background effects */}
      <div className="absolute inset-0 bg-[linear-gradient(to_right,#1a1a1a_1px,transparent_1px),linear-gradient(to_bottom,#1a1a1a_1px,transparent_1px)] bg-[size:4rem_4rem] [mask-image:radial-gradient(ellipse_60%_50%_at_50%_0%,#000_70%,transparent_100%)] opacity-25" />
      <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-green-400/10 to-transparent pointer-events-none" />

      <div className="container mx-auto px-4 relative">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="text-center space-y-6 mb-16"
        >
          <h2 className="text-5xl font-bold font-mono bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-blue-500 animate-text-glow">
            {isDevMode ? (
              <span>
                {">"} load projects --featured
                <span className="ml-2 animate-cursor-blink">_</span>
              </span>
            ) : (
              "Featured Projects"
            )}
          </h2>
          <p className="text-lg text-gray-400 font-mono max-w-2xl mx-auto">
            {isDevMode ? (
              <span className="flex items-center justify-center gap-2">
                <span className="text-blue-400">$</span>
                executing showcase.map(project ={">"} project.status ===
                "production")
              </span>
            ) : (
              "A curated selection of projects that showcase my expertise in building scalable backend solutions and complex systems."
            )}
          </p>
        </motion.div>

        {isLoading && (
          <div className="flex flex-col items-center justify-center gap-4 min-h-[200px] bg-gray-900/50 rounded-xl border-2 border-dashed border-green-400/20">
            <Loader2 className="w-8 h-8 animate-spin text-green-400" />
            {isDevMode && (
              <div className="space-y-2 text-sm text-cyan-400 font-mono text-center">
                <p>$ curl -X GET https://api.skyreal.dev/projects</p>
                <div className="w-64 h-2 bg-gray-800 rounded-full overflow-hidden">
                  <div className="w-full h-full bg-gradient-to-r from-green-400 to-blue-500 animate-progress" />
                </div>
                <p className="text-green-400 animate-pulse">
                  [200] Fetching encrypted project data...
                </p>
              </div>
            )}
          </div>
        )}

        {isSuccess && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {projectData.data.map((project, index) => (
              <Project
                key={project._id}
                project={project}
                index={index}
                isDevMode={isDevMode}
              />
            ))}
          </div>
        )}
      </div>

      {/* Glitch effect SVG filter */}
      <svg className="hidden">
        <filter id="glitch">
          <feTurbulence
            type="fractalNoise"
            baseFrequency="0.8"
            numOctaves="4"
          />
          <feDisplacementMap in="SourceGraphic" scale="8" />
        </filter>
      </svg>
    </section>
  );
};

const Project = ({ project, index, isDevMode }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.8, delay: index * 0.2 }}
      className="group bg-gray-800/50 backdrop-blur-sm rounded-xl border-2 border-green-400/20 hover:border-green-400/40 overflow-hidden hover:shadow-cyber transition-all duration-300 relative"
    >
      <div className="relative overflow-hidden group-hover:[filter:url(#glitch)]">
        <img
          src={project.image}
          alt={project.name}
          className="w-full h-64 object-cover transform group-hover:scale-105 transition-transform duration-300"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent opacity-60" />
        {isDevMode && (
          <div className="absolute top-4 right-4 bg-green-400/20 text-green-400 text-xs px-3 py-1.5 rounded-full font-mono flex items-center gap-2 animate-pulse-slow">
            <div className="w-2 h-2 bg-green-400 rounded-full shadow-glow" />
            STATUS:LIVE
          </div>
        )}
      </div>

      <div className="p-8 space-y-6">
        <div>
          <h3 className="text-2xl font-bold text-gray-200 font-mono mb-4 flex items-center gap-2">
            {isDevMode && <Terminal className="w-5 h-5 text-green-400" />}
            {isDevMode ? `./execute ${project.name}` : project.name}
          </h3>

          <p className="text-gray-400 font-mono line-clamp-3">
            {project.description}
          </p>
        </div>

        <div className="flex flex-col sm:flex-row gap-4 font-mono">
          <a
            href={project.liveUrl}
            target="_blank"
            rel="noreferrer"
            className="flex items-center justify-center gap-2 px-6 py-3 bg-green-400/10 border-2 border-green-400/20 hover:border-green-400 text-green-400 rounded-lg font-mono hover:bg-green-400/20 transition-all duration-300 group shadow-cyber"
          >
            {isDevMode ? (
              <>
                <span className="text-blue-400">$</span>
                ssh deploy@prod --force
              </>
            ) : (
              <>
                <ExternalLink className="w-4 h-4" />
                Visit Site
              </>
            )}
          </a>

          <a
            href={`projects/${project._id}`}
            className="flex items-center justify-center gap-2 px-6 py-3 border-2 border-green-400/20 hover:border-green-400/40 text-green-400 rounded-lg font-semibold transition-all duration-300 hover:shadow-cyber"
          >
            {isDevMode ? (
              <>
                <Terminal className="w-4 h-4" />
                cat readme.md
              </>
            ) : (
              <>
                <Book className="w-4 h-4" />
                Case Study
              </>
            )}
          </a>
        </div>
      </div>
    </motion.div>
  );
};

export default Projects;
