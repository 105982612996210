import React from "react";
import { useNavigate } from "react-router-dom";
import draftToHtml from "draftjs-to-html";

import Form from "../../component/form";
import Button from "../../component/button";
import { api } from "../../utils/api";
import Toast from "../../component/toast";

const CreateBlog = () => {
  const navigate = useNavigate();
  const { mutateAsync, isSuccess, isLoading, isError } = api.usePost("/blog");

  const handleSave = async (data, status = "draft") => {
    const html = draftToHtml(data.body);
    data.body = html;
    if (status === "published") {
      data.status = "published";
    } else {
      data.status = "draft";
    }
    await mutateAsync(data);
  };

  if (isSuccess) {
    setTimeout(() => {
      navigate("/admin/blogs");
    }, 2000);
  }

  return (
    <div className="flex flex-col w-full items-center">
      <Toast isVisible={isError} type="error" />
      <div className="w-[90%]">
        <Form
          enableReinitialize
          initialValues={{
            title: "",
            description: "",
            coverImage: "",
            body: "",
          }}
          onSubmit={(value) => handleSave(value, "published")}
        >
          <Form.Element>
            <h1 className="text-center text-4xl mb-6">Create Post</h1>
            <div className="my-6 border-t-2 border-gray-200"></div>
            <Form.Field.Input name="title" label="Post Title" />
            <Form.Field.Input name="description" label="Post Description" />
            <Form.Field.FileInput name="coverImage" label="Cover Image" />
            <Form.Field.TextEditor name="body" label="Post Body" />
            <Toast
              isVisible={isSuccess}
              type="success"
              timeout={1000}
              message={"Blog created successfully!"}
            />
            <div className="flex p-3 px-0 w-full">
              <Button
                type="submit"
                isWorking={isLoading}
                className="hover:bg-blue-500 text-blue hover:text-white border-blue-500 hover:border-transparent"
              >
                Publish
              </Button>
              <Button
                onClick={handleSave}
                isWorking={isLoading}
                className="hover:bg-red-500 text-red-700 hover:text-white border-red-500 hover:border-transparent "
              >
                Save to draft
              </Button>
            </div>
          </Form.Element>
        </Form>
      </div>
    </div>
  );
};

export default CreateBlog;
