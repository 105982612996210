import React, { useState, useCallback } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import PropTypes from "prop-types";
import "../../noMoreTailwind.css";

import { handleFileUpload } from "../../utils/fileUpload";

const propTypes = {
  placeholder: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
};

const defaultProps = {
  placeholder: "write something...",
  defaultValue: "",
  onChange: () => {},
  className: "",
  readOnly: false,
};

const customContentStateConverter = (contentState) => {
  const newBlockMap = contentState.getBlockMap().map((block) => {
    const entityKey = block.getEntityAt(0);
    if (entityKey !== null) {
      const entityBlock = contentState.getEntity(entityKey);
      const entityType = entityBlock.getType();
      switch (entityType) {
        case "IMAGE": {
          const newBlock = block.merge({
            type: "atomic",
            text: "img",
          });
          return newBlock;
        }
        default:
          return block;
      }
    }
    return block;
  });
  return contentState.set("blockMap", newBlockMap);
};

const TextEditor = ({
  placeholder,
  defaultValue,
  onChange,
  className,
  readOnly,
  ...props
}) => {
  const [editorState, setEditorState] = useState(() => {
    if (!defaultValue) {
      return EditorState.createEmpty();
    }

    const blocksFromHTML = convertFromHTML(defaultValue);
    const state = customContentStateConverter(
      ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      )
    );
    return EditorState.createWithContent(state);
  });

  const getEditorContent = useCallback(() => {
    const editorContent = editorState.getCurrentContent();
    return convertToRaw(editorContent);
  }, [editorState]);

  const handleEditorChange = useCallback(
    (newEditorState) => {
      const content = convertToRaw(newEditorState.getCurrentContent());
      onChange(content);
      setEditorState(newEditorState);
    },
    [onChange]
  );

  const toolbar = {
    options: ["inline", "blockType", "list", "textAlign", "link", "image"],
    inline: {
      options: ["bold", "italic", "underline", "strikethrough"],
      className: "editor-toolbar-option",
    },
    blockType: {
      options: ["Normal", "H1", "H2", "H3", "Blockquote"],
      className: "editor-toolbar-option",
      dropdownClassName: "editor-toolbar-dropdown",
    },
    list: {
      options: ["unordered", "ordered"],
      className: "editor-toolbar-option",
    },
    textAlign: {
      options: ["left", "center", "right"],
      className: "editor-toolbar-option",
    },
    link: {
      options: ["link"],
      className: "editor-toolbar-option",
    },
    image: {
      uploadEnabled: true,
      uploadCallback: handleFileUpload,
      previewImage: true,
      inputAccept: "image/*",
      alt: { present: true, mandatory: false },
      defaultSize: {
        height: "auto",
        width: "100%",
      },
      className: "editor-toolbar-option",
    },
  };

  return (
    <div className={`text-editor-wrapper ${className}`}>
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        placeholder={placeholder}
        readOnly={readOnly}
        getValue={getEditorContent}
        editorClassName="no-more-tailwind editor-content"
        toolbarClassName="editor-toolbar"
        wrapperClassName="editor-wrapper"
        toolbar={toolbar}
        {...props}
      />
    </div>
  );
};

TextEditor.propTypes = propTypes;
TextEditor.defaultProps = defaultProps;

export default TextEditor;
