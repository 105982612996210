import { createContext, useState, useContext, useEffect } from "react";

const DevModeContext = createContext();

export function DevModeProvider({ children }) {
  const [isDevMode, setIsDevMode] = useState(
    () => localStorage.getItem("devMode") === "true" || false
  );

  useEffect(() => {
    localStorage.setItem("devMode", isDevMode);
  }, [isDevMode]);

  const toggleDevMode = () => setIsDevMode((prev) => !prev);

  return (
    <DevModeContext.Provider value={{ isDevMode, toggleDevMode }}>
      {children}
    </DevModeContext.Provider>
  );
}

export const useDevMode = () => useContext(DevModeContext);
