import React from "react";
import { api } from "../utils/api";
import Toast from "../component/toast";
import Form from "../component/form";
import Button from "../component/button";

const Login = () => {
  const {
    mutateAsync,
    isSuccess,
    isError,
    error,
    isLoading,
    data: loginInfo,
  } = api.usePost("/auth/login");

  const { isSuccess: isAuthenticated } = api.useGet("/auth/authenticate");

  if (isAuthenticated) {
    window.location.href = "/admin";
  }

  const handleSubmit = async (value) => {
    await mutateAsync(value);
  };

  if (isSuccess) {
    const token = loginInfo.data.token;
    console.log(token);
    localStorage.setItem("access_token", token);
    setTimeout(() => {
      window.location.href = "/admin";
    }, 2500);
  }
  if (isError) {
    console.log(error);
  }

  return (
    <>
      <Toast
        type="error"
        message={error?.response?.data?.msg}
        isVisible={isError}
      />
      <Toast
        type="success"
        message={"login successful"}
        isVisible={isSuccess}
      />
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 px-6">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-10 w-auto"
            src="https://www.svgrepo.com/show/301692/login.svg"
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <Form
              enableReinitialize
              initialValues={{
                email: "",
                password: "",
              }}
              onSubmit={handleSubmit}
            >
              <Form.Element>
                <Form.Field.Input
                  name="email"
                  label="email"
                  placeholder="user@example.com"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />

                <Form.Field.Input
                  name="password"
                  label="password"
                  type="password"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
                <Button
                  type="submit"
                  isWorking={isLoading}
                  className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                >
                  Sign In
                </Button>
              </Form.Element>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
