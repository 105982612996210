import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { ExternalLink, Github, Terminal, Code2, ArrowLeft } from "lucide-react";
import Footer from "../../component/footer";
import Header from "../../component/header";
import { api } from "../../utils/api";
import EditorContent from "../../component/EditorContent";
import Loading from "../../component/loading";
import { useDevMode } from "../../context/devModeContext";

function StudyProject() {
  const { isDevMode } = useDevMode();
  const projectId = useParams().id;
  const {
    data: study,
    isSuccess,
    isLoading,
  } = api.useGet(`/project/${projectId}`);

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-900">
        <Header />
        <div className="flex flex-col justify-center items-center min-h-[60vh] gap-4">
          <Loading />
          {isDevMode && (
            <p className="text-cyan-400 font-mono text-sm animate-pulse">
              $ loading project_data --id={projectId}...
            </p>
          )}
        </div>
      </div>
    );
  }

  if (isSuccess) {
    const {
      image: img,
      name,
      description,
      body: studyContent,
      liveUrl,
      githubUrl,
      technologies = [],
      createdAt,
    } = study.data;

    return (
      <div className="min-h-screen bg-gray-900">
        <Header />

        <article className="pt-16 pb-24 relative">
          {/* Cyber grid background */}
          <div className="absolute inset-0 bg-[linear-gradient(to_right,#1a1a1a_1px,transparent_1px),linear-gradient(to_bottom,#1a1a1a_1px,transparent_1px)] bg-[size:4rem_4rem] [mask-image:radial-gradient(ellipse_60%_50%_at_50%_0%,#000_70%,transparent_100%)] opacity-25" />

          <div className="max-w-7xl mx-auto px-4 sm:px-6 relative">
            {/* Project Header */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="max-w-4xl mx-auto text-center mb-16 space-y-8"
            >
              <h1 className="text-5xl font-bold font-mono bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-blue-500 animate-text-glow">
                {isDevMode ? (
                  <span>
                    {">"} cat {name}.md
                    <span className="ml-2 animate-cursor-blink">_</span>
                  </span>
                ) : (
                  name
                )}
              </h1>

              <p className="text-xl text-gray-400 font-mono leading-relaxed">
                {isDevMode ? `// ${description}` : description}
              </p>

              {/* Project Links */}
              <div className="flex flex-wrap justify-center gap-4">
                {liveUrl && (
                  <a
                    href={liveUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 px-6 py-3 bg-green-400 text-black rounded-lg font-mono font-semibold hover:bg-green-300 transition-all duration-300 shadow-cyber"
                  >
                    {isDevMode ? (
                      <>
                        <Code2 className="w-4 h-4" />
                        deploy --prod
                      </>
                    ) : (
                      <>
                        <ExternalLink className="w-4 h-4" />
                        Visit Project
                      </>
                    )}
                  </a>
                )}
                {githubUrl && (
                  <a
                    href={githubUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 px-6 py-3 border border-green-400/50 hover:border-green-400 text-green-400 rounded-lg font-mono font-semibold transition-all duration-300 hover:shadow-cyber"
                  >
                    {isDevMode ? (
                      <>
                        <Terminal className="w-4 h-4" />
                        git clone repo
                      </>
                    ) : (
                      <>
                        <Github className="w-4 h-4" />
                        View Code
                      </>
                    )}
                  </a>
                )}
              </div>

              {/* Technologies */}
              {technologies.length > 0 && (
                <div className="flex flex-wrap justify-center gap-3">
                  {technologies.map((tech) => (
                    <span
                      key={tech}
                      className="px-4 py-1 bg-gray-800/50 border border-green-400/20 text-cyan-400 rounded-lg text-sm font-mono"
                    >
                      {isDevMode ? `$ ${tech.toLowerCase()}` : tech}
                    </span>
                  ))}
                </div>
              )}
            </motion.div>

            {/* Project Image */}
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="max-w-6xl mx-auto mb-16"
            >
              <div className="relative rounded-xl overflow-hidden border border-green-400/20 shadow-cyber group">
                <img
                  src={img}
                  alt={name}
                  className="w-full h-[500px] object-cover transform group-hover:scale-105 transition-transform duration-500"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/20 to-transparent opacity-60" />
                {isDevMode && (
                  <div className="absolute top-4 right-4 bg-green-400 text-xs px-2 py-1 rounded-full font-mono text-black">
                    RENDER:SUCCESS
                  </div>
                )}
              </div>
            </motion.div>

            {/* Project Content */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.4 }}
              className="max-w-4xl mx-auto prose prose-lg prose-invert 
                prose-headings:font-mono prose-headings:text-green-400
                prose-p:text-gray-300 prose-p:font-mono
                prose-a:text-cyan-400 prose-a:no-underline hover:prose-a:text-cyan-300
                prose-code:text-green-400 prose-code:bg-gray-800/50 prose-code:px-2 prose-code:py-0.5 prose-code:rounded-md
                prose-pre:bg-gray-800/50 prose-pre:border prose-pre:border-green-400/20
                prose-strong:text-green-400"
            >
              <EditorContent content={studyContent} />
            </motion.div>

            {/* Back to Projects */}
            <div className="max-w-4xl mx-auto mt-16 text-center">
              <a
                href="/projects"
                className="inline-flex items-center gap-2 text-green-400 hover:text-green-300 font-mono group"
              >
                <ArrowLeft className="w-4 h-4 transform group-hover:-translate-x-1 transition-transform" />
                {isDevMode ? "$ cd ../projects" : "Back to Projects"}
              </a>
            </div>
          </div>
        </article>

        <Footer />
      </div>
    );
  }
}

export default StudyProject;
