import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { createPortal } from "react-dom";
import { api } from "../../utils/api";
import Loading from "../../component/loading";
import {
  Eye,
  Edit2,
  Globe,
  CheckSquare,
  MoreHorizontal,
  X,
  Trash2,
} from "lucide-react";

const DeleteConfirmDialog = ({
  isOpen,
  onClose,
  onConfirm,
  projectName,
  isLoading,
}) => {
  return createPortal(
    <div className="fixed inset-0 z-[200] flex items-center justify-center">
      <div className="fixed inset-0 bg-black bg-opacity-50" onClick={onClose} />
      <div
        className="relative bg-white rounded-lg shadow-lg w-full max-w-md mx-4 p-6"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-start mb-4">
          <h3 className="text-lg font-medium">Delete Project</h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X size={20} />
          </button>
        </div>

        <p className="text-sm text-gray-500 mb-6">
          Are you sure you want to delete "{projectName}"? This action cannot be
          undone.
        </p>

        <div className="flex justify-end gap-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
            disabled={isLoading}
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700 flex items-center"
            disabled={isLoading}
          >
            {isLoading ? (
              <div className="flex items-center justify-center w-full min-w-[80px] gap-2">
                <Loading size="small" className="text-white" />
                <span className="animate-pulse">Deleting...</span>
              </div>
            ) : (
              <div className="flex items-center justify-center w-full min-w-[80px] gap-2 transition-all duration-200 group-hover:gap-3">
                <Trash2
                  size={16}
                  className="group-hover:scale-110 transition-transform duration-200"
                />
                <span>Delete</span>
              </div>
            )}
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
};

const ActionMenuItem = ({
  icon,
  label,
  onClick,
  isLoading,
  className = "",
}) => (
  <button
    className={`w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center space-x-2 ${className}`}
    onClick={onClick}
    disabled={isLoading}
  >
    {isLoading ? <Loading size="small" /> : icon}
    <span>{label}</span>
  </button>
);

const ProjectCard = ({ project, isSelected, onSelect }) => {
  const { name, description, liveUrl, image, state, _id: id } = project;
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const buttonRef = useRef(null);

  const {
    mutateAsync: updateState,
    isSuccess: stateUpdateIsSuccess,
    isLoading: stateUpdateIsLoading,
  } = api.usePut(`/project/${id}/state`);

  const {
    mutateAsync: deleteProject,
    isSuccess: deleteIsSuccess,
    isLoading: deleteIsLoading,
  } = api.useDelete(`/project/${id}`);

  useEffect(() => {
    if (stateUpdateIsSuccess || deleteIsSuccess) {
      window.location.reload();
    }
  }, [stateUpdateIsSuccess, deleteIsSuccess]);

  const updatePosition = useCallback(() => {
    if (buttonRef.current && showDropdown) {
      const rect = buttonRef.current.getBoundingClientRect();
      const spaceOnRight = window.innerWidth - rect.right;
      const spaceBelow = window.innerHeight - rect.bottom;

      const top = spaceBelow < 200 ? rect.top - 150 : rect.bottom + 8;

      setDropdownPosition({
        top,
        left: spaceOnRight < 200 ? rect.right - 192 : rect.left,
      });
    }
  }, [showDropdown]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdownMenu = document.querySelector("[data-dropdown-menu]");
      if (dropdownMenu?.contains(event.target)) {
        return;
      }
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("scroll", updatePosition, true);
      window.addEventListener("resize", updatePosition);
      updatePosition();
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("scroll", updatePosition, true);
      window.removeEventListener("resize", updatePosition);
    };
  }, [showDropdown, updatePosition]);

  const handleProjectState = async () => {
    try {
      await updateState({
        state: state === "published" ? "draft" : "published",
      });
      setShowDropdown(false);
    } catch (error) {
      console.error("Failed to update project state:", error);
    }
  };

  const handleProjectDelete = async () => {
    try {
      await deleteProject();
      setShowDeleteConfirm(false);
      setShowDropdown(false);
    } catch (error) {
      console.error("Failed to delete project:", error);
    }
  };

  const ActionMenu = () => {
    if (!showDropdown) return null;

    return createPortal(
      <div
        data-dropdown-menu
        className="fixed z-[100] w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
        style={{
          top: `${dropdownPosition.top}px`,
          left: `${dropdownPosition.left}px`,
        }}
      >
        <div className="py-1">
          <ActionMenuItem
            icon={<Eye size={16} />}
            label="View Project"
            onClick={() => {
              navigate(`/projects/${id}`);
              setShowDropdown(false);
            }}
          />
          <ActionMenuItem
            icon={<Edit2 size={16} />}
            label="Edit Project"
            onClick={() => {
              navigate(`/admin/project/${id}/edit`);
              setShowDropdown(false);
            }}
          />
          {liveUrl && (
            <ActionMenuItem
              icon={<Globe size={16} />}
              label="View Live"
              onClick={() => {
                window.open(liveUrl, "_blank");
                setShowDropdown(false);
              }}
            />
          )}
          <ActionMenuItem
            icon={
              state === "published" ? (
                <Edit2 size={16} />
              ) : (
                <CheckSquare size={16} />
              )
            }
            label={state === "published" ? "Make Draft" : "Publish"}
            onClick={handleProjectState}
            isLoading={stateUpdateIsLoading}
          />
          <div className="border-t border-gray-100 my-1" />
          <ActionMenuItem
            icon={<Trash2 size={16} />}
            label="Delete"
            onClick={() => {
              console.log("Delete");
              setShowDeleteConfirm(true);
              setShowDropdown(false);
            }}
            className="text-red-600 hover:text-red-700 hover:bg-red-50"
          />
        </div>
      </div>,
      document.body
    );
  };

  return (
    <>
      <div
        className={`
          relative bg-white rounded-lg shadow-sm hover:shadow-md 
          transition-all duration-200 overflow-hidden
          ${isSelected ? "ring-2 ring-blue-500" : ""}
        `}
      >
        <div className="absolute top-2 left-2 z-10">
          <input
            type="checkbox"
            checked={isSelected}
            onChange={onSelect}
            className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
          />
        </div>

        <div className="relative aspect-video overflow-hidden bg-gray-100">
          <img src={image} alt={name} className="w-full h-full object-cover" />
          <span
            className={`
              absolute top-2 right-2 px-2 py-1 rounded-full text-xs font-medium
              ${
                state === "published"
                  ? "bg-green-100 text-green-800"
                  : "bg-yellow-100 text-yellow-800"
              }
            `}
          >
            {state === "published" ? "Published" : "Draft"}
          </span>
        </div>

        <div className="p-4">
          <div className="flex items-start justify-between">
            <div className="flex-1">
              <h3 className="font-medium text-gray-900 line-clamp-1">{name}</h3>
              <p className="mt-1 text-sm text-gray-500 line-clamp-2">
                {description}
              </p>
            </div>

            <button
              ref={buttonRef}
              onClick={() => setShowDropdown(!showDropdown)}
              className="p-1 rounded-full hover:bg-gray-100 transition-colors"
            >
              <MoreHorizontal size={20} className="text-gray-400" />
            </button>
          </div>
        </div>
      </div>
      <ActionMenu />
      {showDeleteConfirm && (
        <DeleteConfirmDialog
          isOpen={showDeleteConfirm}
          onClose={() => setShowDeleteConfirm(false)}
          onConfirm={handleProjectDelete}
          projectName={name}
          isLoading={deleteIsLoading}
        />
      )}
    </>
  );
};

export default ProjectCard;
